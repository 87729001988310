import React from "react"
import { graphql } from "gatsby";
import { Layout } from "@components/Layout";
import { mapToSEO, SEO } from "@components/SEO";
import { Grid } from "semantic-ui-react";
import { PlateOwnerSelection } from "@components/PlateOwnerSelection";
import { mapToCheckoutSelectionCard } from "@mappers/Checkout/CheckoutSelectionCard";
import OrderSummary from "@components/OrderSummary/OrderSummary";
import theme from "@styles/theme";
import { GenericBannerContainer } from "@components/GenericBannerContainer";

import { Record } from 'immutable';
import { connect } from 'react-redux';
import { Dispatch } from "redux"
import { currentCheckoutSelector } from "@redux/selectors/checkoutSelector";
import { updateManufactureNow} from "@redux/actions";
import { updateGiftingType, updateIsGifting, updateOwnerIsApplicant, updatePersonalDetails } from "@redux/actions/checkout";
import { currentBasketSelector } from "@redux/selectors/basketSelector";
import { isMobile } from "@utils/Helpers";
import MobilePlateOwnerSelection from "@components/Mobile/PlateOwnerSelection/PlateOwnerSelection";
import { NavHeader } from "@components/Mobile/NavHeader/NavHeader";
import MobileGenericBannerContainer from "@components/Mobile/GenericBannerContainer/GenericBannerContainer";
import { Checkout } from "@models/Checkout/Checkout";
import { mapToCheckoutGiftingTemplate } from "@components/Mobile/GiftingSelection/mapper";
import { TransactionTypesSetting } from "@models/ProductModels";
import { getCurrentBasket } from "@redux/localStorage/basket";

const PlateOwnerSelectionPageContainer = (props:any) => {

    const {elements} = props.data.allKontentItemPlateOwnerSelectionPage.nodes.find((node: any) => {return node.elements.heading.value == 'Plate Owner'});
    const apiUrl = props.data.site.siteMetadata.apiUrl;
    const seo = mapToSEO(elements);
    const applicantCard = mapToCheckoutSelectionCard(elements.plate_owner_card.linked_items[0].elements);
    const giftingCardRedesign = mapToCheckoutSelectionCard(elements.gifting_card_redesign.linked_items[0].elements);
    const forSomeoneElseCard = mapToCheckoutSelectionCard(elements.gifting_card.linked_items[0].elements);
    const giftingCard = mapToCheckoutSelectionCard(elements.plate_owner_card_dealer.linked_items[0].elements);
    const giftingCardMobileRedesign = mapToCheckoutSelectionCard(elements.gifting_card_mobile_redesign.linked_items[0].elements);
    const eCard = mapToCheckoutSelectionCard(elements.plate_owner_card_dealer.linked_items[1].elements);
    const giftingCardMobile = mapToCheckoutSelectionCard(elements.gifting_card_dealer.linked_items[0].elements);
    const giftingOptionElements = props.data.allKontentItemPlateOwnerSelectionPage.nodes.find((node: any) => {return node.elements.heading.value == 'Personalise Your Ecard'});
    const eCardTemplate = mapToCheckoutGiftingTemplate(elements.plate_owner_card_dealer.linked_items[1].elements.gifting_template.linked_items[0].elements);
    const transactionTypeId = getCurrentBasket().basketLines.length > 0 ? getCurrentBasket().basketLines[0].transactionTypeId : TransactionTypesSetting.New.id;

    if(isMobile() === undefined){
      return null;
    }

    return (
    <Layout version="simple" currentStep={3} location={props.location}>
        <SEO {...seo} />
        <MobileGenericBannerContainer
          padding={{
            mobile: {
                top: 20,
                bottom: 40
            },
            desktop: {
                top: 40,
                bottom: 60
            }
          }}
          backgroundColor={isMobile() ? theme.mobileTheme.bgColor : theme.brand.colors.beige}>
          <Grid stackable reversed='mobile tablet vertically' stretched>
                  <Grid.Column mobile={16} tablet={16} computer={10}>
                    {isMobile() ?
                    <MobilePlateOwnerSelection
                      heading={elements.heading.value}
                      subHeading={elements.sub_heading.value}
                      body={elements.body.value}
                      applicantCard={applicantCard}
                      updateOwnerIsApplicant={props.updateOwnerIsApplicant}
                      updateManufactureNow={props.updateManufactureNow}
                      updateIsGifting={props.updateIsGifting}
                      updateGiftingType={props.updateGiftingType}
                      checkout={props.checkoutState}
                      transactionTypeId={transactionTypeId}
                      forSomeoneElseCard={transactionTypeId == TransactionTypesSetting.New.id ? forSomeoneElseCard : giftingCardRedesign}
                      giftingCard={giftingCard} /> :
                    <PlateOwnerSelection
                      heading={elements.heading.value}
                      subHeading={elements.sub_heading.value}
                      body={elements.body.value}
                      giftingOptionElements={giftingOptionElements}
                      applicantCard={applicantCard}
                      updateOwnerIsApplicant={props.updateOwnerIsApplicant}
                      updateManufactureNow={props.updateManufactureNow}
                      updateIsGifting={props.updateIsGifting}
                      updateGiftingType={props.updateGiftingType}
                      updatePersonalDetails={props.updatePersonalDetails}
                      checkout={props.checkoutState}
                      giftingCard={giftingCard}
                      eCard={eCard}
                      forSomeoneElseCard={transactionTypeId == TransactionTypesSetting.New.id ? forSomeoneElseCard : giftingCardRedesign}
                      apiUrl={apiUrl}
                      transactionTypeId={transactionTypeId}
                      eCardTemplate={eCardTemplate}/>}
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={16} computer={6} floated='right'>
                    {isMobile() ?
                    <NavHeader link="/shopping-cart" info="Plate Owner"/>:
                    <OrderSummary isExpanded={false} isLayby={false} showSurcharge={false} basket={props.basket} apiUrl={apiUrl} giftType={props.checkoutState.giftingType}/>}
                  </Grid.Column>
          </Grid>
        </MobileGenericBannerContainer>
    </Layout>);

}


const mapStateToProps = (state: Record<any>) => {
  const checkout = currentCheckoutSelector(state).toJS();
  const basket = currentBasketSelector(state).toJS();
  return {
    checkoutState: checkout,
    basket: basket
  };
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateOwnerIsApplicant(ownerIsApplicant:boolean){
    dispatch(updateOwnerIsApplicant(ownerIsApplicant));
},
updateManufactureNow(manufactureNow:boolean){
    dispatch(updateManufactureNow(manufactureNow));
},
updateIsGifting(isGifting:boolean){
  dispatch(updateIsGifting(isGifting));
},
updateGiftingType(giftingType: number) {
  dispatch(updateGiftingType(giftingType));
},
updatePersonalDetails(personalDetails: Checkout) {
  dispatch(updatePersonalDetails(personalDetails))
}
})

const PlateOwnerSelectionPage = connect(mapStateToProps,mapDispatchToProps)(PlateOwnerSelectionPageContainer)


export const query = graphql`
{
  site {
    siteMetadata {
      apiUrl
    }
  }
  allKontentItemPlateOwnerSelectionPage(filter: {elements: {heading: {value: {in: ["Plate Owner","Personalise Your Ecard"]}}}}) {
    nodes {
      elements {
        body {
          value
        }
        heading {
          value
        }
        seo__nofollow {
          value {
            codename
            name
          }
        }
        seo__noindex {
          value {
            codename
            name
          }
        }
        seo__page_description {
          value
        }
        seo__page_title {
          value
        }
        url{
          value
        }
        sub_heading {
          value
        }
        plate_owner_card {
          linked_items {
            ... on KontentItemCheckoutSelectionCard {
              id
              elements {
                body {
                  value
                }
                heading {
                  value
                }
                icon {
                  value {
                    url
                    name
                    description
                  }
                }
              }
            }
          }
        }
        gifting_card {
          linked_items {
            ... on KontentItemCheckoutSelectionCard {
              id
              elements {
                body {
                  value
                }
                heading {
                  value
                }
                icon {
                  value {
                    url
                    name
                    description
                  }
                }
              }
            }
          }
        }
        plate_owner_card_dealer {
          linked_items {
            ... on KontentItemCheckoutSelectionCard {
              id
              elements {
                body {
                  value
                }
                content{
                  value
                }
                heading {
                  value
                }
                icon {
                  value {
                    url
                    name
                    description
                  }
                }
                gifting_template {
                  linked_items {
                    ... on KontentItemCheckoutGiftingOptionItem {
                      elements {
                        message {
                          value
                        }
                        declaration {
                          value
                        }
                        footer1 {
                          value
                        }
                        footer2 {
                          value
                        }
                        footer3 {
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        gifting_card_dealer {
          linked_items {
            ... on KontentItemCheckoutSelectionCard {
              id
              elements {
                body {
                  value
                }
                heading {
                  value
                }
                icon {
                  value {
                    url
                    name
                    description
                  }
                }
              }
            }
          }
        }
        gifting_card_mobile_redesign {
          linked_items {
            ... on KontentItemCheckoutSelectionCard {
              id
              elements {
                body {
                  value
                }
                heading {
                  value
                }
                icon {
                  value {
                    url
                    name
                    description
                  }
                }
              }
            }
          }
        }
        gifting_card_redesign {
          linked_items {
            ... on KontentItemCheckoutSelectionCard {
              id
              elements {
                body {
                  value
                }
                heading {
                  value
                }
                icon {
                  value {
                    url
                    name
                    description
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
  `

export default PlateOwnerSelectionPage;