import React from 'react'
import { H1, H2, H3, H5, Paragraph1 } from '@styles/Global.styles'
import { Grid, Icon } from 'semantic-ui-react'
import { CheckoutSelectionCard } from '@components/CheckoutSelectionCard'
import { PlateOwnerCardsContainer, PlateOwnerSelectionSubHeading } from './PlateOwnerSelection.styles'
import { CheckoutSelectionCardModel } from '@models/Checkout/CheckoutSelectionCard'
import { KpButton } from '@elements/index'
import theme from '@styles/theme'
import { Checkout } from '@models/Checkout/Checkout'
import { CheckoutSelectionCardContainer, CheckoutSelectionCardHeading, CheckoutSelectionCardIndicator } from '@components/CheckoutSelectionCard/CheckoutSelectionCard.styles'
import { GiftingOptionsSelectionCardButton } from '@components/Mobile/GiftingSelection/GiftingCheckoutSelectionCard.styles'
import EcardContainer from '@components/GiftingSelection/EcardContainer'
import { CheckoutGiftingCardModel } from '@components/Mobile/GiftingSelection/mapper'
import { getCurrentCheckout } from '@redux/localStorage/checkout'
import { EcardImg } from '@components/Mobile/GiftingSelection/EcardContainer.style'
import { navigate } from 'gatsby'
import CheckoutSelectionCardWithRadios from '@components/CheckoutSelectionCard/CheckoutSelectionCardWithRadios'
import { TransactionTypesSetting } from '@models/ProductModels'
import RadioCheckoutSelectionCard from '@components/CheckoutSelectionCard/RadioCheckoutSelectionCard'

const ecardImg = require('@assets/images/ecard.png')

export type PlateOwnerSelectionProps = {
    heading: string
    subHeading: string
    body: string
    applicantCard: CheckoutSelectionCardModel
    giftingCard: CheckoutSelectionCardModel
    forSomeoneElseCard: CheckoutSelectionCardModel
    eCard: CheckoutSelectionCardModel
    updateOwnerIsApplicant: (ownerIsApplicant:boolean) => void
    updateManufactureNow: (manufactureNow:boolean)=>void
    updateIsGifting: (isGifting: boolean) => void
    updateGiftingType: (giftingType: number) => void
    updatePersonalDetails: (personalDetails: Checkout) => void
    checkout: Checkout
    giftingOptionElements: any
    apiUrl: string
    eCardTemplate: CheckoutGiftingCardModel
    transactionTypeId: number
}

interface States {

}

class PlateOwnerSelection extends React.Component<PlateOwnerSelectionProps, States>{

  
    selectApplicantOption = () => {
        this.props.updateOwnerIsApplicant(true);
        this.props.updateIsGifting(false);
        this.props.updateGiftingType(0)
        if(!this.props.checkout.isLayby) this.props.updateManufactureNow(true);
    }

    selectGifting = () => {
        this.props.updateIsGifting(true);
        this.props.updateOwnerIsApplicant(false);
        this.props.updateManufactureNow(false);
        if(this.props.checkout.giftingType !== 1 && this.props.checkout.giftingType !== 2){
            this.props.updateGiftingType(2) // Default to Gift Box if previously not set
        }
    }


    selectBuyOnBehalf = () => {
        this.props.updateIsGifting(true);
        this.props.updateGiftingType(0)
        this.props.updateOwnerIsApplicant(false);
        this.props.updateManufactureNow(false);
    }

    continue= () =>{
        if(this.props.checkout.isGifting && (this.props.checkout.giftingType == 1 || this.props.checkout.giftingType == 2)){
            navigate('/select-gifting-type/')
        }else if(this.props.checkout.ownerIsApplicant && !this.props.checkout.isLayby) {
            navigate('/plate-manufacture-selection/')
        } else{
            navigate('/personal-details/')
        }
    }

    render(){

        const {heading, subHeading, body, applicantCard, giftingCard, forSomeoneElseCard, checkout} = this.props;

        return (
            <Grid>
            <Grid.Row>
                <Grid.Column width={16}>
                    <H2>
                        {heading}
                    </H2>
                    <PlateOwnerSelectionSubHeading>{subHeading}</PlateOwnerSelectionSubHeading>
                    <Paragraph1 color='#707070'>
                        {body}
                    </Paragraph1>
                    <PlateOwnerCardsContainer>
                        <Grid.Row>
                        <RadioCheckoutSelectionCard card={applicantCard} onSelect={() => this.selectApplicantOption()} isSelected={checkout.ownerIsApplicant}></RadioCheckoutSelectionCard>
                        </Grid.Row>
                        <Grid.Row>
                        <RadioCheckoutSelectionCard card={giftingCard} onSelect={() => this.selectGifting()} isSelected={!checkout.ownerIsApplicant && checkout.isGifting && (checkout.giftingType == 1 || checkout.giftingType == 2)}></RadioCheckoutSelectionCard>
                        </Grid.Row>                        
                        <Grid.Row>
                        <RadioCheckoutSelectionCard card={forSomeoneElseCard} onSelect={() => this.selectBuyOnBehalf()} isSelected={!checkout.ownerIsApplicant && checkout.isGifting && checkout.giftingType == 0}></RadioCheckoutSelectionCard>
                        </Grid.Row>
                    </PlateOwnerCardsContainer>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row verticalAlign='bottom'>
                <Grid.Column width={16}>
                <Grid reversed='mobile vertically'>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                    <KpButton id="pos-back" fullWidth="mobile"  buttonType='secondary' color={theme.brand.colors.black} link='/shopping-cart'>BACK</KpButton>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={8} textAlign='right'>
                    <KpButton id="pos-continue" fullWidth="mobile"  buttonType='primary' color={theme.brand.colors.blue} onClick={() => this.continue()} >CONTINUE</KpButton>
                    </Grid.Column>
                </Grid>
                </Grid.Column>
            </Grid.Row>
        </Grid>
        );
    }
}
export default PlateOwnerSelection