import React from 'react'
import {
    CheckoutSelectionCardContainer,
    CheckoutSelectionCardIndicator,
    CheckoutSelectionCardHeading,
    CheckoutSelectionCardButton,
    CheckoutIconImage,
    SelectionSubTitleWrapper,
    SelectionSubTitleText,
    PlatemanufactureSelectionCardButton

} from './CheckoutSelectionCard.styles'
import { Paragraph2 } from '@styles/Global.styles'
import theme from '@styles/theme'
import { Icon, Divider, Label, Grid, Radio } from 'semantic-ui-react'
import { CheckoutSelectionCardModel } from '@models/Checkout/CheckoutSelectionCard'
import { SelectedItem } from '@components/Mobile/PlateManufactureSelection/PlateManufactureSelection.styles'

const checkoutIcon = require('@assets/images/checkout.png')
const expresscheckoutIcon = require('@assets/images/express-checkout.png')
export type RadioCheckoutSelectionCardProps = {
    card: CheckoutSelectionCardModel
    onSelect: Function
    isSelected: boolean
    manufactureSelection?: boolean
    expressCheckout?: boolean
    hideButton?: boolean
}

class RadioCheckoutSelectionCard extends React.Component<RadioCheckoutSelectionCardProps>{
    render() {

        const { heading, body, content, items, icon } = this.props.card;
        const { isSelected, manufactureSelection, expressCheckout, hideButton = false } = this.props;

        let isSelectedClass = isSelected;
        if(manufactureSelection && manufactureSelection == true){
            isSelectedClass = false
        }

        return (
            <CheckoutSelectionCardContainer className={isSelectedClass ? 'selected' : isSelected ? 'selectedMan' : ''} onClick={() => this.props.onSelect()}>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div style={{textAlign: 'left'}}>
                        <CheckoutSelectionCardHeading><img src={icon[0].url} alt={icon[0].description} />{heading}</CheckoutSelectionCardHeading>
                        {body && <span style={{color: theme.brand.colors.darkGrey}}>{body}</span>}
                        {/* {content && <span style={{color: theme.brand.colors.darkGrey}} dangerouslySetInnerHTML={{ __html: content }} />}  */}
                        { !manufactureSelection && manufactureSelection == false &&<Paragraph2 color='#707070' dangerouslySetInnerHTML={{ __html: content }} />}
                    </div>
                    {/* <div style={{textAlign: 'right'}}>
                        <Radio checked={isSelected} onChange={() => this.props.onSelect()} />
                    </div> */}
                </div>
                
                {
                    isSelected &&
                    <CheckoutSelectionCardIndicator>
                        <Icon name='check' size='small' />
                    </CheckoutSelectionCardIndicator>
                }
            </CheckoutSelectionCardContainer>
        );
    }
}



export default RadioCheckoutSelectionCard