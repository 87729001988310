import styled from "@emotion/styled";
import { H5 } from "@styles/Global.styles";
import { Grid } from "semantic-ui-react";

export const PlateOwnerSelectionSubHeading = styled(H5)`
    margin-bottom: 10px !important;
`

export const PlateOwnerCardsContainer = styled(Grid)`
    margin-left:0px !important;
    margin-right:0px !important;
`