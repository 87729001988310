import React from 'react'
import { H2, H4, Paragraph1 } from '@styles/Global.styles'
import { Grid } from 'semantic-ui-react'
import { CheckoutSelectionCard } from '@components/CheckoutSelectionCard'
import { PlateOwnerSelectionSubHeading } from './PlateOwnerSelection.styles'
import { CheckoutSelectionCardModel } from '@models/Checkout/CheckoutSelectionCard'
import { KpButton } from '@elements/index'
import theme from '@styles/theme'
import { Checkout } from '@models/Checkout/Checkout'
import { navigate } from 'gatsby'
import { TransactionTypesSetting } from '@models/ProductModels'
import { getCurrentCheckout } from '@redux/localStorage/checkout'
import CheckoutSelectionCardWithIcon from '@components/GiftingSelection/CheckoutSelectionCardWithIcon'

export type PlateOwnerSelectionProps = {
    heading: string
    subHeading: string
    body: string
    applicantCard: CheckoutSelectionCardModel
    giftingCard: CheckoutSelectionCardModel
    forSomeoneElseCard: CheckoutSelectionCardModel
    updateOwnerIsApplicant: (ownerIsApplicant:boolean) => void
    updateManufactureNow: (manufactureNow:boolean)=>void
    updateIsGifting: (isGifting: boolean) => void
    updateGiftingType: (giftingType: number) => void
    checkout: Checkout
    transactionTypeId: number
}

interface States {
    ownerIsApplicant: boolean
    giftingType : number
}

class MobilePlateOwnerSelection extends React.Component<PlateOwnerSelectionProps, States>{
    constructor(props: PlateOwnerSelectionProps) {
        super(props)
        this.state = {
            ownerIsApplicant: false,
            giftingType : getCurrentCheckout().giftingType,
        }
    }

    selectApplicantOption = () => {
        
        this.setState({giftingType: 0})
        this.props.updateGiftingType(0)     

        this.props.updateOwnerIsApplicant(true);
        this.props.updateIsGifting(false);
        if(!this.props.checkout.isLayby) this.props.updateManufactureNow(true);

        this.setState({
            ownerIsApplicant: true
        }, () => {
            setTimeout(() => {
                var url = (this.props.checkout.ownerIsApplicant && !this.props.checkout.isLayby) ? '/plate-manufacture-selection/' : '/personal-details/'
                navigate(url)
            }, 500);
        })


    }    

    selectGifting = () => {
        this.props.updateIsGifting(true);
        this.props.updateOwnerIsApplicant(false);
        this.props.updateManufactureNow(false);   
        if(this.props.checkout.giftingType !== 1 && this.props.checkout.giftingType !== 2){
            this.props.updateGiftingType(2) // Default to Gift Box if previously not set
        }

        setTimeout(() => {            
            var url = '/select-gifting-type/' 
            navigate(url)
        }, 500);
    }


    selectBuyOnBehalf = () => {
        this.props.updateIsGifting(true);
        this.props.updateGiftingType(0)
        this.props.updateOwnerIsApplicant(false);
        this.props.updateManufactureNow(false);   

        setTimeout(() => {            
            var url =  '/personal-details/'
            navigate(url)
        }, 500);
    }


    render(){

        const {heading, subHeading, body, applicantCard, giftingCard, checkout, forSomeoneElseCard} = this.props;

        return (
            <Grid>
            <Grid.Row>
                <Grid.Column width={16}>
                    <H2>{subHeading}</H2>
                    <Paragraph1>
                        {body}
                    </Paragraph1>
                    <Grid stretched>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                            <CheckoutSelectionCardWithIcon card={applicantCard} onSelect={() => this.selectApplicantOption()} isSelected={this.state.ownerIsApplicant} hideButton={true}></CheckoutSelectionCardWithIcon>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                            <CheckoutSelectionCardWithIcon card={giftingCard} onSelect={() => this.selectGifting()} isSelected={!checkout.ownerIsApplicant && checkout.isGifting && (checkout.giftingType == 1 || checkout.giftingType == 2)} hideButton={true}></CheckoutSelectionCardWithIcon>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                            <CheckoutSelectionCardWithIcon card={forSomeoneElseCard} onSelect={() => this.selectBuyOnBehalf()} isSelected={!checkout.ownerIsApplicant && checkout.isGifting && checkout.giftingType == 0} hideButton={true}></CheckoutSelectionCardWithIcon>
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid.Row>
        </Grid>
        );
    }
}
export default MobilePlateOwnerSelection